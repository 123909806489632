<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input
            type="text"
            v-model="searchParams.search"
            v-on:keyup.enter="handleSearch"
            class="form-control form-control-solid w-250px me-2"
            placeholder="Search Option Name"
          />
        </div>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
          <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
        </el-tooltip>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
        </el-tooltip>
      </div>
      <div class="card-toolbar">
        <button type="button" class="btn btn-primary" v-if="permission.isAddAllowed" @click="$router.push({ name: 'add-product-option' })"><i class="bi bi-plus-circle-fill fs-4"></i>
          {{ generalConstants.ADD }}
        </button>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
      >
        <template v-slot:cell-id="{ row: data }">
          {{ data }}
        </template>
        <template v-slot:cell-option_name="{ row: data }">
          {{ data.option_name }}
        </template>
        <template v-slot:cell-sort_order="{ row: data }">
          <div v-if="permission.isEditAllowed">
            <el-input-number
              v-model="data.option_sort_order"
              size="medium"
              controls-position="right"
              @change="changeSortOrder($event, data.master_option_id)"
            />
          </div>
          <div v-else>
            --
          </div>
        </template>
        <template v-slot:cell-status="{ row: data }">
          <div v-if="permission.isEditAllowed">
            <el-switch
              v-model="data.option_status"
              @change="changeStatus($event, data.master_option_id)"
              active-color="#13ce66"
              inactive-color="#ff4949"
            />
          </div>
          <div v-else>
            --
          </div>
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <div v-if="permission.isEditAllowed || permission.isDeleteAllowed">
            <el-tooltip class="box-item" effect="dark" v-if="permission.isViewAllowed" :content="generalConstants.VIEW" placement="top">
              <button type="button" @click.prevent="$router.push({ name: 'view-product-option', params: { id: data.master_option_id } })" class="btn btn-icon btn-light btn-sm me-2">
                <i class="far fa-eye"></i>
              </button>
            </el-tooltip>
            <el-tooltip class="box-item" effect="dark" v-if="permission.isEditAllowed" :content="generalConstants.EDIT" placement="top">
              <button type="button" @click.prevent="$router.push({ name: 'edit-product-option', params: { id: data.master_option_id } })" class="btn btn-icon btn-light btn-sm me-2">
                <i class="bi bi-pencil-square"></i>
              </button>
            </el-tooltip>
            <el-tooltip class="box-item" effect="dark" v-if="permission.isDuplicateAllowed" :content="generalConstants.DUPLICATE" placement="top">
              <button type="button" @click="duplicateProductOption(data)" class="btn btn-icon btn-light btn-sm me-2">
                <i class="far fa-clone"></i>
              </button>
            </el-tooltip>
            <el-tooltip class="box-item" effect="dark" v-if="permission.isDeleteAllowed" :content="generalConstants.DELETE" placement="top">
              <button type="button" @click.prevent="remove(data.master_option_id, tableData.findIndex((i) => i.master_option_id === data.master_option_id))" class="btn btn-icon btn-light btn-sm">
                <i class="bi bi-trash text-danger"></i>
              </button>
            </el-tooltip>
          </div>
          <div v-else>
            -
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { notificationFire } from "@/composable/notification.js";
import { formatText } from "@/core/helpers/common";
import { useRoute, useRouter } from "vue-router";
import { ElMessageBox } from 'element-plus'
import globalConstant from "@/core/data/globalConstant.js";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { useStore } from "vuex";

export default {
  name: "product-options",
  components: {
    Datatable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const updateProps = ref(null);
    const search = ref("");
    const pageSize = ref(10);
    const total = ref(0);
    const tableData = ref([]);
    const currentpage = ref(1);
    const loading = ref(false);
    const generalConstants = globalConstant.general;
    const noDataFound = ref(generalConstants.LOADING);
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const searchParams = reactive({
      search: store.getters.getSearch(moduleName.value),
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value),
      sortBy: "sort_order",
      sortDesc: "asc"
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
      isDeleteAllowed: false,
      isViewAllowed: false,
      isDuplicateAllowed: false,
    })
    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
      },
      {
        name: "Option Name",
        key: "option_name",
        sortable: true,
        width: "w-200px",
      },
      {
        name: "Sort Order",
        key: "sort_order",
        width: "w-100px",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        width: "w-200px",
        sortable: true,
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        width: "w-200px",
        sortable: false,
      },
    ]);

    //Change Product Master Sort Order
    const changeSortOrder = (number, id) => {
      ApiService.post("product-master-option/sortorderupdate", {
        number: number,
        id: id,
      })
        .then(({ data }) => {
          notificationFire(data.message, "success");
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    };

    //Change Product Master Status
    const changeStatus = (status, id) => {
      ApiService.post("product-master-option/statusupdate", {
        status: status,
        id: id,
      })
        .then(({ data }) => {
          notificationFire(data.message, "success");
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    };

    //Get all Master Option
    const getProductMasterOptions = async () => {
      loading.value = true;
      await ApiService.query("product-master-option", { params: searchParams })
        .then(({ data }) => {
          if (data.data.data.length) {
            tableData.value = [];
            data.data.data.forEach((obj) => {
              tableData.value.push({
                master_option_id: obj.master_option_id,
                option_name: obj.option_name,
                option_description: obj.option_description,
                option_sort_order: obj.sort_order,
                option_status: obj.status == 1 ? true : false,
              });
            });
            total.value = data.data.total;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
          } else {
            tableData.value = [];
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
            total.value = 0;
          }
          setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
          loading.value = false;
        })
        .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            tableData.value = [];
            loading.value = false;
          });
    };

    //Delete Product Master Record
    const remove = async (id, index) => {
      ElMessageBox.confirm(
        generalConstants.DELETE_MESSAGE,
        generalConstants.ALERT_WARNING,
        {
          confirmButtonText: generalConstants.ALERT_CONFIMATION,
          cancelButtonText: generalConstants.ALERT_CANCEL,
          type: 'warning',
          center: true
        }
      )
      .then(() => {
        ApiService.delete("product-master-option/"+id)
        .then((response) => {
          let status = response.status;
          let message = response.data.message;
          if (status == 200) {
            notificationFire(message, 'success');
            tableData.value.splice(index, 1);
            total.value = total.value > 0  ? total.value - 1 : 0;
            setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
            if(tableData.value.length == 0){
                updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
            }
          }
        })
        .catch((error) => {
          let message = error.message;
          notificationFire(message, 'error');
        });
      })
    };

    //For Pagination Size Change
    const handleSizeChange = (number) => {
        store.dispatch(Actions.PER_PAGE, {'module':moduleName.value,'value':number});
        searchParams.recordsPerPage = store.getters.getPerPage(moduleName.value);
        handleCurrentChange(1);
    };

    //For Pagination Number Change
    const handleCurrentChange = (number) => {
      store.dispatch(Actions.PAGE_NUMBER, {'module':moduleName.value,'value':number});
      searchParams.pageNub = store.getters.getPageNumber(moduleName.value);
      getProductMasterOptions();
    };

    //For Search Box handle event
    const handleSearch = () => {
      if(searchParams.search.length > 0){
        store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
        handleCurrentChange(1);
      }
    };

    //Reset search value
    const resetSearch = () => {
      if(searchParams.search.length > 0){
        searchParams.search = '';
        store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
        getProductMasterOptions();
      }
    }

    //Duplicate product option
    const duplicateProductOption = (data) => {
      ElMessageBox.confirm(
        'Do you really want to duplicate this record?<br> <strong>Note: This will duplicate attributes records related to this product option. System will add the "Copy" word in Product option name for user identification.</strong>',
        generalConstants.ALERT_WARNING,
        {
          confirmButtonText: generalConstants.ALERT_CONFIMATION,
          cancelButtonText: generalConstants.ALERT_CANCEL,
          type: 'warning',
          dangerouslyUseHTMLString: true,
          center: true
        })
      .then(() => {
        ApiService.post("product-master-option/duplicate-product-option", {
          master_option_id: data.master_option_id,
        })
        .then((data) => {
          let status = data.status;
          let message = data.data.message;
          if (status == 200) {
            notificationFire(message, 'success');
            router.push({name: "edit-product-option",params: { id: data.data.data },});
          }
        })
        .catch((error) => {
          let message = error.message;
          notificationFire(message, 'error');
        });
      })
    }

    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("createProductMasterOption") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateProductMasterOption") || role == "Super Admin")
        permission.isEditAllowed = true;
      if (per.includes("deleteProductMasterOption") || role == "Super Admin")
        permission.isDeleteAllowed = true;
      if (per.includes("viewProductOption") || role == "Super Admin")
        permission.isViewAllowed = true;
      if (per.includes("duplicateProductOption") || role == "Super Admin")
        permission.isDuplicateAllowed = true;
      await getProductMasterOptions();
    })

    return {
      tableData,
      search,
      loading,
      getProductMasterOptions,
      total,
      currentpage,
      pageSize,
      handleSizeChange,
      handleCurrentChange,
      handleSearch,
      resetSearch,
      remove,
      changeStatus,
      changeSortOrder,
      searchParams,
      tableHeader,
      generalConstants,
      updateProps,
      noDataFound,
      permission,
      duplicateProductOption
    };
  },
};
</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
</style>
